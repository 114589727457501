<template>
  <div class="raffle_ticket">
    <div class="top_ponts">
      <img src="../../assets/images/ponits/icon_jf.png" alt="" />
      <span>我的积分</span>
    </div>
    <div>
      <div class="container">
        <p class="points_all">{{ points }}</p>
        <div class="cont_center">
          <div
            v-for="(item, index) in DrawList"
            :key="index"
            class="prize-item"
            :class="currentIndex === index ? 'active' : ''"
          >
            <img :src="item.gift_img" alt="" />
            <p class="desc">{{ item.prize_name }}</p>
          </div>
        </div>
        <div class="cont_bottom" @click="startdraw()">
          <img src="../../assets/images/ponits/bat_ljcj.png" alt="" />
          <div class="cb_points">{{ lottery_points }}积分/次</div>
        </div>
      </div>
    </div>
    <div class="rulur_text">
      <div class="ru_top">抽奖规则</div>
      <p v-html="rutext"></p>
    </div>
    <van-popup v-model="cnetershow" :close-on-click-overlay="false">
      <div class="center_popu">
        <div class="zhanshi">
          <div class="jiang_cen">
            <p class="winText" v-if="winningData.id != 6">恭喜中奖</p>
            <p class="winText_grid" v-else>感谢参与</p>
            <img
              class="firdt_img"
              v-if="winningData.id != 6"
              :src="winningData.gift_img"
              alt=""
            />
            <img
              class="second_img"
              v-else
              src="../../assets/images/ponits/img_wzj_.png"
              alt=""
            />
          </div>
          <div class="jiang_good" v-if="winningData.id != 6">抽中{{ winningData.prize_name }}</div>
          <div class="jiang_good" v-else>您与奖品擦肩而过</div>
        </div>
        <img @click="closeCenter" src="../../assets/images/ponits/icon_gb_cpy (1).png" alt="" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  prizeInfo,
  prizeDraw,
  UserArticle,
  UsersCheck,
} from "../../api/myORlogin";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      cnetershow: false,
      winningData: {},
      rutext: "",
      lottery_points: 0,
      points: 0,
      position: 0,
      pirzeList: [],
      count: 0, // 抽奖次数
      isDrawing: false, // 是否正在抽奖
      currentIndex: null, // 当前选中的奖品位置
      circle: 24, // 一圈6个奖品，至少转4圈
      drawOrder: [0, 1, 2, 5, 4, 3], // 抽奖顺序
    };
  },
  computed: {
    DrawList() {
      return this.pirzeList;
    },
  },
  watch: {},
  created() {
    this.getprizeInfo();
    this.getUserArticle();
    this.getusersCheck();
  },
  methods: {
    closeCenter(){
      this.cnetershow=false
    },
    getusersCheck() {
      UsersCheck()
        .then((res) => {
          console.log(res);
          this.lottery_points = res.data.lottery_points;
          this.points = res.data.points;
        })
        .catch((err) => {});
    },
    getUserArticle() {
      UserArticle({ cat_id: 5 }).then((res) => {
        this.rutext = res.data.content;
      });
    },
    getprizeInfo() {
      prizeInfo().then((res) => {
        console.log(res);
        this.pirzeList = res.data;
      });
    },
    startdraw() {
      console.log("开始抽奖");
        // 开始抽奖
        if (this.isDrawing) {
          return;
        }
        this.isDrawing = true;
        //获取后台返回的中奖等级
        prizeDraw().then((res) => {
          this.winningData = res.data;
          //找出中奖等级位置
          this.position = this.pirzeList.findIndex(
            (item) => item.id == res.data.id
          );
          const timer = setInterval(() => {
          this.currentIndex =
            this.drawOrder[this.count % this.drawOrder.length];
          this.count++;
          if (this.count > this.circle && this.currentIndex === this.position) {
            console.log(this.currentIndex);
            // 抽奖结束
            clearInterval(timer);
            this.getusersCheck();
            // 停顿一会显示中奖
            setTimeout(() => {
              this.cnetershow=true
              this.isDrawing = false;
              this.count = 0;
              // this.currentIndex = null;//回到最初位置
            }, 400);
          }
        }, 70);
        }).catch(err=>{
          this.isDrawing = false
           console.log('积分不足');
        })
      }
  },
};
</script>

<style scoped lang="less">
.jiang_cen {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%);
  .winText {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 60px;
    color: #ec3a5b;
  }
  .winText_grid{
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 60px;
    color: #666666;
  }
  .second_img {
    width: 216px;
    height: 216px;
  }
  .firdt_img {
    width: 222px;
    height: 234px;
  }
}
.jiang_good {
  width: 400px;
  font-weight: bold;
  font-size: 36px;
  color: #ffffff;
  position: absolute;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
}
.van-popup {
  background-color: transparent !important;
}
.center_popu {
  text-align: center;
  > img {
    width: 60px;
    height: 60px;
  }
}
.zhanshi {
  position: relative;
  margin-bottom: 20px;
  width: 530px;
  height: 891px;
  background-image: url("../../assets/images/ponits/icon_gb_cpy (2).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.ru_top {
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 36px;
  color: #577bb5;
  line-height: 60px;
}
.rulur_text {
  overflow: auto;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #577bb5;
  line-height: 60px;
  padding: 35px;
  margin: 20px auto;
  width: 690px;
  min-height: 430px;
  background: rgba(195, 218, 246, 0.34);
  box-shadow: 1px 9px 9px 1px rgba(255, 255, 255, 0.45);
  border-radius: 30px;
}
.raffle_ticket {
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(0deg, #edf1f4 0%, #dde9fc 100%);
}
.top_ponts {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 27px 0 0 40px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #666666;
  img {
    margin-right: 5px;
    width: 35px;
    height: 35px;
  }
}
.cont_bottom {
  margin: 85px auto 0 auto;
  position: relative;
  width: 426px;
  height: 120px;
  img {
    width: 426px;
    height: 120px;
  }
  .cb_points {
    padding: 0 12px;
    position: absolute;
    top: -11px;
    right: -90px;
    height: 40px;
    background: rgba(255, 255, 255, 0.76);
    box-shadow: 2px 3px 8px 0px rgba(60, 101, 161, 0.2);
    border-radius: 20px 20px 20px 0px;
    border: 2px solid #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #577bb5;
  }
}
.cont_center {
  margin: 248px auto 0 auto;
  width: 600px;
  height: 330px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.container {
  position: relative;
  padding: 1px;
  width: 700px;
  height: 838px;
  background-image: url("../../assets/images/ponits/img_jfbj.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: auto;
  .points_all {
    line-height: 60px;
    font-family: DIN;
    font-weight: bold;
    font-size: 60px;
    color: #222c29;
    position: absolute;
    top: 0;
    left: 15px;
  }

  .prize-item {
    margin-left: 35px;
    width: 150px;
    height: 150px;
    background: #ffffff;
    border-radius: 16px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #577bb5;
    img {
      width: 76px;
      height: 76px;
      margin-top: 20px;
    }
  }

  .active {
    box-shadow: 0px 0px 11px 3px #ff9e2c;
  }
}
</style>
